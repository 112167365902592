const menu = {
  data: [
    {
      title: '植入管理',
      link: 'implantManage',
      route_name: 'implantManage',
      icon_class: 'el-icon-s-shop',
      id: 1001,
      children: [
        {
          title: '回执单',
          link: 'receiptManage',
          route_name: 'receiptManage',
          id: 107
        },
        {
          title: '用户',
          link: 'userManage',
          route_name: 'userManage',
          id: 0
        },
        {
          title: 'ICM事件',
          link: 'icmEvents',
          route_name: 'icmEvents',
          id: 1002
        },
        {
          title: '用户反馈',
          link: 'feedBack',
          route_name: 'feedBack',
          id: 7
        }
      ]
    },
    // {
    //   title: '小程序记录',
    //   link: 'list',
    //   route_name: 'samplingRecordList',
    //   icon_class: 'el-icon-s-check',
    //   id: 1
    // },
    // {
    //   title: '蓝牙网关记录',
    //   link: 'bluetoothGatewayRecord',
    //   route_name: 'bluetoothGatewayRecord',
    //   icon_class: 'el-icon-s-promotion',
    //   id: 2
    // },
    {
      title: '无双设备管理',
      link: 'deviceManage',
      route_name: 'deviceManage',
      icon_class: 'el-icon-s-platform',
      id: 4,
      children: [
        {
          title: '产品型号',
          link: 'productModelManage',
          route_name: 'productModelManage',
          id: 101
        },
        // {
        //   title: '生产批号管理',
        //   link: 'verifyDataConfig',
        //   route_name: 'verifyDataConfig',
        //   id: 102
        // },
        {
          title: 'ICM设备',
          link: 'ICMDeviceManage',
          route_name: 'ICMDeviceManage',
          id: 104
        },
        // {
        //   title: 'HFM设备管理',
        //   link: 'verifyDataConfig',
        //   route_name: 'verifyDataConfig',
        //   id: 105
        // },
        {
          title: '蓝牙网关设备',
          link: 'homeMonitor',
          route_name: 'homeMonitor',
          id: 106
        }
      ]
    },
    {
      title: '系统配置',
      link: 'systemConfig',
      route_name: 'systemConfig',
      icon_class: 'el-icon-s-custom',
      id: 1003,
      children: [
        {
          title: '医院配置',
          link: 'hospitalManage',
          route_name: 'hospitalManage',
          id: 5
        },
        {
          title: '设备运行日志',
          link: 'deviceOperateLogs',
          route_name: 'deviceOperateLogs',
          id: 108
        },
        {
          title: '固件版本',
          link: 'FirmwareManage',
          route_name: 'FirmwareManage',
          id: 103
        },
        {
          title: '无双员工配置',
          link: 'staffManage',
          route_name: 'staffManage',
          id: 6
        }
      ]
    }
  ]
}
export default menu
